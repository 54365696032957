<template>
  <div class="loader">
    <div class="loader__wave"/>
    <div class="loader__image"/>
<!--    <svg class="loader__svg" viewBox="-10 -10 220 220">-->
<!--      <path d="A 100 100 90 0 0 110 110"/>-->
<!--      <path :d="path"/>-->
<!--    </svg>-->
  </div>
</template>

<script>
  export default {
    name: 'Loader',
    data: () => ({
      phase: 0,
    }),
    computed: {
      path() {
        return `M 200 100 A 100 100 0 0 1 93 200`
      }
    }
  }
</script>

<style scoped lang="stylus">
  .loader
    @apply fixed z-10 inset-0
    display grid
    grid-template-columns 1fr auto 1fr
    grid-template-rows 1fr auto 1fr
    grid-template-areas ". . ." ". a ." ". . ."

  .loader__image
    grid-area a
    @apply rounded-full z-10
    width 200px
    height 200px
    background-image url("~@/assets/logo/ChannelQuiz_logo.png"), linear-gradient(-135deg, #d7ecff, #a2d0ff)
    background-size contain
    box-sizing border-box

  /*.loader__svg*/
    /*grid-area a*/
    /*width 220px*/
    /*height 220px*/
    //
    /*path*/
      /*fill none*/
      /*stroke lightslategray*/
      /*stroke-width 5*/
      /*stroke-linecap round*/

  .loader__wave
    grid-area a
    @apply rounded-full
    width 200px
    height 200px
    transform-origin center
    background-image radial-gradient(transparent, #a2d0ff)
    animation loader__wave 2s infinite

  @keyframes loader__wave
    0%
      opacity 1
      /*transform none*/
    80%, 100%
      opacity 0
      transform scale(2)

</style>